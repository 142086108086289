const TurboNativeBridge = {

  bridge: 'New shiny turbo bridge',

  typeCastData(action, data) {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'setBadgeCount':
        data.count = parseInt(data.count, 10);
        break;
    }
  },

  // Sends a message to the native app, if active.
  postMessage(name, data = {}) {
    // eslint-disable-next-line no-console
    this.typeCastData(name, data);

    // eslint-disable-next-line no-console
    console.log(`[TurboNativeBridge] ${name} ${JSON.stringify(data)}`);

    // iOS
    if (window.webkit) {
      return window.webkit?.messageHandlers?.nativeApp?.postMessage({ name, ...data });
    }

    // Android
    if (window.nativeApp) {
      return window.nativeApp?.postMessage(JSON.stringify({ name, ...data }));
    }

    return null;
  },

  setAuthToken(token) {
    return this.postMessage('setAuthToken', { token });
  },

  /**
   * Call native function that will set push notification token
   * via API call
   */
  registerForPushNotifications() {
    return this.postMessage('registerForPushNotifications');
  },

  triggerShare(url) {
    return this.postMessage('share', { url });
  },

  copyToClipboard(text) {
    return this.postMessage('copyToClipboard', { text });
  },

  openInBrowser(url) {
    return this.postMessage('openInBrowser', { url });
  },

  back() {
    return this.postMessage('back');
  },

  downloadImage(url, filename) {
    return this.postMessage('downloadImage', { url, filename });
  },

  preview(url, filename) {
    return this.postMessage('preview', { url, filename });
  },

  setBadgeCount(count) {
    return this.postMessage('setBadgeCount', { count });
  },

  nativeLog(msg) {
    return this.postMessage('nativeLog', { msg });
  },

  setChatsCount(count) {
    return this.postMessage('setChatsCount', { count });
  },

  setNotificationsCount(count) {
    return this.postMessage('setNotificationsCount', { count });
  },
};

export default TurboNativeBridge;
