export default class NormalizedEvent {
  constructor(event) {
    this.event = event;
  }

  get isTouchEvent() {
    return this.event.type.includes('touch');
  }

  get x() {
    return this.isTouchEvent ? this.event.touches[0].clientX : this.event.clientX;
  }

  get y() {
    return this.isTouchEvent ? this.event.touches[0].clientY : this.event.clientY;
  }

  get moveEventName() {
    return this.isTouchEvent ? 'touchmove' : 'mousemove';
  }

  get endEventName() {
    return this.isTouchEvent ? 'touchend' : 'mouseup';
  }
}
