const selection = window.getSelection();
const range = document.createRange();

export class CopyNodeToClipboard {
  constructor(el) {
    this.el = el;
  }

  getStyle() {
    this.style = this.el.style.userSelect;
    this.webkitStyle = this.el.style.webkitUserSelect;
  }

  setStyle() {
    this.el.style.userSelect = 'text';
    this.el.style.webkitUserSelect = 'text';
  }

  revertStyle() {
    this.el.style.userSelect = this.style;
    this.el.style.webkitUserSelect = this.webkitStyle;
  }

  setNodeRange() {
    range.selectNodeContents(this.el);
    return range;
  }

  selectNode() {
    this.setNodeRange();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  clearSelection() {
    window.getSelection().removeAllRanges();
  }

  copy() {
    document.execCommand('copy');
  }

  start() {
    this.getStyle();
    this.setStyle();
    this.selectNode();
    this.copy();
    this.clearSelection();
    this.revertStyle();
  }
}

export default function copyNodeToClipboard(el) {
  return new CopyNodeToClipboard(el).start();
}
