// eslint-disable-next-line import/no-extraneous-dependencies
import copyNodeToClipboard from '@effectivastudio/copy_node_to_clipboard';

function createTempElement(value) {
  const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
  const el = document.createElement('div');
  // Prevent zooming on iOS
  el.style.fontSize = '12pt';
  // Reset box model
  el.style.border = '0';
  el.style.padding = '0';
  el.style.margin = '0';
  // Move element out of screen horizontally
  el.style.position = 'fixed';
  el.style[isRTL ? 'right' : 'left'] = '-9999px';
  el.style.top = 0;

  el.setAttribute('contentEditable', '');
  el.innerHTML = value;

  return el;
}

export default function copyToClipboard(value) {
  const element = createTempElement(value);

  document.body.appendChild(element);
  copyNodeToClipboard(element);
  element.remove();
}
