let pushTokenUpdatingInProgress = false;

const APP_OLD_VERSION = '2.3.1';
const UA = window.navigator.userAgent;
const APP_VERSION = (UA.match(/ver:([0-9.]+)/) || [])[1];
const IS_ANDROID = UA.includes('client:android');
const IS_IOS = UA.includes('client:ios');
const APP_PREVIOUS_VERSION = '2.3.4';

const IS_MOBILE = IS_ANDROID || IS_IOS;

function defer() {
  const deferred = {};

  // eslint-disable-next-line compat/compat
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
}

const Adapter = {

  bridge: 'Turbolinks bridge',

  APP_OLD_VERSION,

  APP_VERSION,

  APP_PREVIOUS_VERSION,

  IS_ANDROID,

  IS_IOS,

  IS_MOBILE,

  pushToken: null,

  pushTokenUpdated: false,

  shouldUpdatePushToken: null,
  getAuthToken: null,

  loggedIn() {
    return this.shouldUpdatePushToken();
  },

  resetState() {
    // if(this.loggedIn()) { return; }

    console.log('[resetState] Triggered... '); // eslint-disable-line
    this.pushToken = null;
    this.pushTokenUpdated = false;
  },

  requestAndUpdatePushTokenOnServerIfNeeded() {
    if (APP_VERSION <= APP_OLD_VERSION) {
      Adapter.updatePushToken({ push_token: Adapter.getPushToken('') });
      return;
    }

    if (!this.pushTokenUpdated && this.loggedIn()) {
      this.requestPushToken();
    }
  },

  // Update push token in database for current device
  // Mobile app will trigger this function after we call requestPushToken
  onNewPushToken(opts) {
    const pushToken = opts.push_token || opts;

    // Expose pushToken for testing
    this.pushToken = pushToken;

    console.log('[updatePushToken] Triggered... '); // eslint-disable-line

    if (pushTokenUpdatingInProgress) {
      console.log('[updatePushToken] SKIPPING: ajax call already in progress...'); // eslint-disable-line
      return;
    }

    if (!this.loggedIn()) {
      console.log('[updatePushToken] user not logged in'); // eslint-disable-line
      return;
    }

    if (!this.pushTokenUpdated) {
      console.log('[updatePushToken] SKIPPING: push token already updated'); // eslint-disable-line
    }

    if (!pushToken) {
      throw new Error('[updatePushToken] onNewpushToken: pushToken is missing');
    }

    console.log('[updatePushToken] Updating... '); // eslint-disable-line
    pushTokenUpdatingInProgress = true;

    window.$.ajax({
      type: 'PATCH',
      url: '/device_push_token',
      data: { push_token: pushToken },
    })
      .done(() => {
        console.log('[updatePushToken] Successfuly updated'); // eslint-disable-line
        this.pushTokenUpdated = true;
      })
      .fail((xhr) => {
        const error = new Error(xhr.responseText);
        error.name = '[updatePushToken] Failed';
        throw error;
      })
      .always(() => {
        console.log('[updatePushToken] cleanup'); // eslint-disable-line
        pushTokenUpdatingInProgress = false;
      });
  },

  // Call JS in Mobile devices
  adapter(method, ...args) {
    const { AndroidApp, webkit } = window;

    if (IS_ANDROID) {
      return AndroidApp[method](...args);
    }

    if (IS_IOS) {
      // iOS needs at least one argument
      !args.length && args.push('');
      return webkit.messageHandlers[method].postMessage(...args);
    }

    return null;
  },

  // Get push token from mobile device (TODO: remove when all devices upgrade to 2.3.4)
  // https://app.asana.com/0/1115714411658649/1153423027129601
  // this function on iOs is a requestPushToken
  // Old FN on Android has function argument arrity length of 1
  getPushToken() {
    // OLD FN
    try {
      return this.adapter('getPushToken', '');
    } catch (e) {} // eslint-disable-line

    // NEW FN
    return this.adapter('getPushToken');
  },

  // requestPushToken will call onNewpushToken on device with token as parameter
  requestPushToken() {
    return this.adapter('requestPushToken');
  },

  // Save auth token from server to mobile device
  setAuthToken(token) {
    return token && this.adapter('setAuthToken', token);
  },

  triggerShare(url) {
    return this.adapter('share', url);
  },

  back() {
    return this.adapter('back');
  },

  downloadImage(url) {
    return this.adapter('downloadImage', url);
  },

  copyToClipboard(text) {
    return this.adapter('copyToClipboard', text);
  },

  setVariable(name, value) {
    return this.adapter('setVariable', JSON.stringify({ [name]: value }));
  },

  requestVariable(name) {
    this.defers[name] = defer();
    this.adapter('requestVariable', name);
    return this.defers[name].promise;
  },

  defers: {},
  variables: {},
  requestPromise: null,

  requestVariableCallback(name, value) {
    this.variables[name] = value;
    this.defers[name].resolve(value);
  },

  openInBrowser(url) {
    return this.adapter('openInBrowser', url);
  },

  // Android only

  showUrlDialog(url) {
    return this.adapter('showUrlDialog', url);
  },

  showSnackBar(title) {
    return this.adapter('showSnackBar', title);
  },

  showErrDialog(title, msg) {
    return this.adapter('showErrDialog', title, msg);
  },

  start() {
    // Alias for old with version < 2.3.4
    Adapter.updatePushToken = Adapter.onNewPushToken;

    // Events
    document.addEventListener('turbolinks:load', () => {
      // Reset state when user not logged in
      !this.loggedIn() && Adapter.resetState();

      Adapter.setAuthToken(this.getAuthToken());
      Adapter.requestAndUpdatePushTokenOnServerIfNeeded();
    });

    // We need this because mobile bridge is calling methods from mobile side
    window.App ||= {};

    App.Mobile = Adapter;
  },
};

export default Adapter;
