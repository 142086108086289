// constants
export const ZOOM_CONSTANT = 15; // increase or decrease value for zoom on mouse wheel
export const MOUSE_WHEEL_COUNT = 5; // A mouse delta after which it should stop preventing default behaviour of mouse wheel

export function noop() {}
export function identity(x) { return x; }

// ease out method
/*
    t : current time,
    b : intial value,
    c : changed value,
    d : duration
*/
export function easeOutQuart(t, b, c, d) {
  const ft = (t / d) - 1;
  return -c * (ft * ft * ft * ft - 1) + b;
}

export function createElement(options) {
  const elem = document.createElement(options.tagName);
  if (options.id) elem.id = options.id;
  if (options.html) elem.innerHTML = options.html;
  if (options.className) elem.className = options.className;
  if (options.src) elem.src = options.src;
  if (options.style) elem.style.cssText = options.style;
  if (options.child) elem.appendChild(options.child);

  // Insert before
  if (options.insertBefore) {
    options.parent.insertBefore(elem, options.insertBefore);

  // Standard append
  } else {
    options.parent.appendChild(elem);
  }

  return elem;
}

// function to check if image is loaded
export function imageLoaded(img) {
  return img.complete && (typeof img.naturalWidth === 'undefined' || img.naturalWidth !== 0);
}

export function toArray(list) {
  if (!(list instanceof NodeList || list instanceof HTMLCollection)) return [list];
  return Array.prototype.slice.call(list);
}

export function remove(elements) {
  toArray(elements).forEach((element) => {
    element.parentNode.removeChild(element);
  });
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export function assignEvent(element, events, handler) {
  if (typeof events === 'string') events = [events];

  events.forEach((event) => {
    element.addEventListener(event, handler);
  });

  return () => {
    events.forEach((event) => {
      element.removeEventListener(event, handler);
    });
  };
}

export function getTouchPointsDistance(touches) {
  const touch0 = touches[0];
  const touch1 = touches[1];
  return Math.sqrt(Math.pow(touch1.pageX - touch0.pageX, 2) + Math.pow(touch1.pageY - touch0.pageY, 2));
}

export function toFloat(number) {
  return parseFloat(number, 10);
}

export function toInt(number) {
  return parseInt(number, 10);
}

// cross-browser wheel delta
export function normalizedMouseDelta(event) {
  return Math.max(-1, Math.min(1, event.wheelDelta || -event.detail || -event.deltaY));
}

export function inRange(value, { min, max }) {
  return value >= min && value <= max;
}

export function squareCenter({ w, h }) {
  return {
    x: w / 2,
    y: h / 2,
  };
}

export function getElementDimensions(el, transform) {
  const { width, height, top, left } = window.getComputedStyle(el);
  const method = transform || identity;

  return {
    top: method(top),
    left: method(left),
    width: method(width),
    height: method(height),
  };
}

export function loadImage(src, onload) {
  const img = new Image();

  if (imageLoaded(img)) {
    onload();
  } else {
    img.onload = onload;
  }

  img.src = src;
}
