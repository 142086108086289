/* eslint-disable max-classes-per-file */

// {
//   title_publisher: {
//    'cardTitle-publisherName': true
//   }
//   publisher: {
//     publisher1: [item, item, item]
//   },
// }
//
//

export class Index {
  constructor(name) {
    this.name = name;
    this.constructor.all[name] = {};
  }

  static all = {}

  has(key) {
    return key in this.container;
  }

  write(key, value) {
    this.container[key] = value;
    return value;
  }

  read(key) {
    return this.container[key];
  }

  incCount(key) {
    return this.write(key, (this.read(key) || 0) + 1);
  }

  push(key, value) {
    this.container[key] = this.container[key] || [];
    this.container[key].push(value);
    return value;
  }

  last(key, n = 1) {
    const arr = this.arrayFor(key);
    return arr ? arr[this.length(key) - n] : arr;
  }

  previous(key) {
    return this.last(key, 2);
  }

  length(key) {
    const arr = this.arrayFor(key);
    return arr ? arr.length : 0;
  }

  arrayFor(key) {
    return this.container[key];
  }

  get container() {
    return this.constructor.all[this.name];
  }

  set container(value) {
    return this.constructor.all[this.name] = value;
  }

  clear() {
    this.container = {};
  }
}

export class Item {
  constructor(attributes) {
    this.attributes = attributes;
    this.chainNext = false;
    this.chainPrev = false;
    this.locked = this.attributes.isCollapseLocked;
    this.duplicate = false;
    if (!this.locked && this.isDuplicate()) {
      this.markAsDuplicate();
      this.chain();
    }

    this.allItems.push(this);
  }

  // Class props and methods
  static all = [];

  static reset = () => {
    this.rules.forEach(rule => rule.index.clear());
    this.all = [];
  };

  // Instance props and methods

  get allItems() {
    return this.constructor.all;
  }

  onMarkAsDuplicate() { }

  markAsDuplicate() {
    this.duplicate = true;
    this.onMarkAsDuplicate();
  }

  isDuplicate() {
    return this.constructor.rules.some(rule => rule.applicable(this));
  }

  shouldChain() {
    if (!this.prev) { return false; }

    return this.attributes.card_publisher === this.prev.attributes.card_publisher;
  }

  chain() {
    if (this.shouldChain()) {
      this.chainPrev = true;

      if (!this.prev.chainPrev) {
        this.prev.chainNext = true;
      }
    }
  }

  get prev() {
    return this.constructor.all[this.constructor.all.length - 1];
  }
}

export class ItemDom extends Item {
  // eslint-disable-next-line no-unused-vars
  static parseElement = (target) => {
    throw new Error('Not implemented. Please implement in project.');
  }

  static fromElement = (target) => {
    if (!target) { return false; }
    const { id, isCollapseLocked } = target.dataset;
    const parsed = this.parseElement(target);

    const item = {
      id,
      target,
      isCollapseLocked: isCollapseLocked === 'true',
      ...parsed,
    };

    return new this(item);
  }

  get target() {
    return this.attributes.target;
  }

  isDuplicate() {
    return !this.isInjected && super.isDuplicate();
  }

  get isInjected() {
    return this.target.classList.contains('injected');
  }

  onMarkAsDuplicate() {
    this.collapse();
  }

  addClass(name) {
    this.target.classList.add(name);
  }

  hasClass(name) {
    this.target.classList.contains(name);
  }

  chain() {
    super.chain();

    this.chainPrev && this.addClass('card-chain-prev');
    this.prev && this.prev.chainNext && this.prev.addClass('card-chain-next');
  }

  collapse() {
    this.addClass('card-smart-collapsed');
  }
}

export class Rule {
  static minutes(min) {
    return min * 60 * 1000;
  }

  constructor(name, validator) {
    this.name = name;
    this.validator = validator;
    this.index = new Index(name);
  }

  applicable(item) {
    return this.validator(this, item);
  }
}

export function SameAttributesRule({ collapseAfter = 1, attributes }) {
  const ruleName = attributes.join('_and_');

  return new Rule(ruleName, (rule, item) => {
    const key = attributes.map(attr => item.attributes[attr]).join('-');

    rule.index.incCount(key);

    return rule.index.read(key) > collapseAfter;
  });
}
