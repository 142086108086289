import debounce from 'lodash/debounce';
import EventEmitter from 'events';

import {
  noop,
  getElementDimensions,
  squareCenter,
  inRange,
  normalizedMouseDelta,
  toInt,
  toFloat,
  createElement,
  remove,
  easeOutQuart,
  imageLoaded,
  clamp,
  assignEvent,
  getTouchPointsDistance,
  ZOOM_CONSTANT,
  MOUSE_WHEEL_COUNT,
} from './util';

import Slider from './slider';
import './viewer.scss';

const fullScreenHtml = `
  <div class="iv-fullscreen-container iv-container"></div>
  <div class="iv-fullscreen-actions">
    <a href="#" class="iv-fullscreen-close"></a>
  </div>
`;

const imageViewHtml = `
  <div class="iv-loader"></div>
  <div class="iv-snap-view">
    <div class="iv-snap-image-wrap">
      <div class="iv-snap-handle"></div>
    </div>
    <div class="iv-zoom-slider">
      <div class="iv-zoom-handle"></div>
    </div>
  </div>
  <div class="iv-image-view">
    <div class="iv-image-wrap"></div>
  </div>
`;

class Viewer {
  static instances = [];

  static register(instance) {
    return this.instances.push(instance);
  }

  static unregister(instance) {
    const index = this.instances.indexOf(instance);
    this.instances.splice(index, 1);
    return this.instances;
  }

  static destroyAll() {
    this.instances.forEach(i => i.destroy());
    this.instances = [];
  }

  constructor(options = {}) {
    const fullScreenElem = createElement({
      tagName: 'div',
      className: 'iv-fullscreen',
      html: fullScreenHtml,
      parent: document.body,
    });

    const container = fullScreenElem.querySelector('.iv-fullscreen-container');

    // containers for elements
    this._elements = {
      container,
      fullScreen: fullScreenElem,
      actions: fullScreenElem.querySelector('.iv-fullscreen-actions'),
    };

    this._options = { ...Viewer.defaults, ...options };

    // container for all events
    this.dom_events = {};

    // container for all timeout and frames
    this.frames = {};

    // container for all sliders
    this._sliders = {};

    // maintain current state
    this._state = {
      zoomValue: this._options.zoomValue,
    };

    this._images = {};

    this.init();

    this.onHide = options.onHide || noop;

    // store reference of Viewer in container
    container._Viewer = this;
    this.emitter = new EventEmitter();

    this.constructor.register(this);
  }

  emit(...args) {
    this.emitter.emit(...args);
    return this;
  }

  on(...args) {
    this.emitter.on(...args);
    return this;
  }

  init() {
    // initialize the dom elements
    this.initDom();

    // initialize slider
    this._initImageSlider();
    this._initSnapSlider();
    this._initZoomSlider();

    // enable pinch and zoom feature for touch screens
    this._pinchAndZoom();

    // enable scroll zoom interaction
    this._scrollZoom();

    // enable double tap to zoom interaction
    // this.doubleTapToZoom(); // TODO: disabled until made better

    // initialize events
    this.initEvents();
  }

  initDom() {
    const { container } = this._elements;

    // add image-viewer layout elements
    createElement({
      tagName: 'div',
      className: 'iv-wrap',
      html: imageViewHtml,
      parent: container,
    });

    // save references for later use
    this._elements = {
      ...this._elements,
      snapView: container.querySelector('.iv-snap-view'),
      snapImageWrap: container.querySelector('.iv-snap-image-wrap'),
      imageWrap: container.querySelector('.iv-image-wrap'),
      snapHandle: container.querySelector('.iv-snap-handle'),
      zoomHandle: container.querySelector('.iv-zoom-handle'),
      closeButton: document.querySelector('.iv-fullscreen-close'),
      loader: container.querySelector('.iv-loader'),
    };
  }

  _initImageSlider() {
    const { _elements } = this;

    const { imageWrap } = _elements;

    let positions;
    let currentPos;

    // MAIN IMAGE *******************
    /* Add slide interaction to image */
    const imageSlider = new Slider(imageWrap, {
      isSliderEnabled: () => {
        const { loaded, zooming, zoomValue } = this._state;
        return loaded && !zooming && zoomValue > 100;
      },
      onStart: (e, position) => {
        const { snapSlider } = this._sliders;

        // clear all animation frame and interval
        this._clearFrames();

        snapSlider.onStart();

        // reset positions
        positions = [position, position];
        currentPos = position;

        this.frames.slideMomentumCheck = setInterval(() => {
          positions = [
            positions[1],
            {
              x: currentPos.mx,
              y: currentPos.my,
            },
          ];
        }, 50);
      },

      onMove: (e, position) => {
        const { snapImageDim } = this._state;
        const { snapSlider } = this._sliders;
        const imageCurrentDim = this.getImageCurrentDim();
        currentPos = position;

        snapSlider.onMove(e, {
          dx: -position.dx * snapImageDim.w / imageCurrentDim.w,
          dy: -position.dy * snapImageDim.h / imageCurrentDim.h,
        });
      },

      onEnd: () => {
        const { snapImageDim } = this._state;
        const { snapSlider } = this._sliders;
        const imageCurrentDim = this.getImageCurrentDim();

        // clear all animation frame and interval
        this._clearFrames();

        let step = 1;
        let positionX = currentPos.dx;
        let positionY = currentPos.dy;

        const xDiff = positions[1].x - positions[0].x;
        const yDiff = positions[1].y - positions[0].y;

        // MOMENTUM #important
        const STEPS = 60; // How long momentum should last
        const MOMENTUM_TRIGGER_TRESHOLD = 30;
        const shouldActivateMomentum = Math.abs(xDiff) > MOMENTUM_TRIGGER_TRESHOLD || Math.abs(yDiff) > MOMENTUM_TRIGGER_TRESHOLD;

        const momentum = () => {
          if (step <= STEPS) {
            this.frames.sliderMomentumFrame = requestAnimationFrame(momentum);
          }

          positionX += easeOutQuart(step, xDiff / 3, -xDiff / 3, STEPS);
          positionY += easeOutQuart(step, yDiff / 3, -yDiff / 3, STEPS);

          snapSlider.onMove(null, {
            dx: -(positionX * snapImageDim.w / imageCurrentDim.w),
            dy: -(positionY * snapImageDim.h / imageCurrentDim.h),
          });

          step++;
        };

        shouldActivateMomentum && momentum();
      },
    });

    imageSlider.init();

    this._sliders.imageSlider = imageSlider;
  }


  // Snap slider is miniview
  _initSnapSlider() {
    const { snapHandle } = this._elements;

    let startHandleTop;
    let startHandleLeft;

    const snapSlider = new Slider(snapHandle, {
      isSliderEnabled: () => this._state.loaded,
      onStart: () => {
        const { slideMomentumCheck, sliderMomentumFrame } = this.frames;

        const computedStyle = getElementDimensions(snapHandle, toFloat);
        startHandleTop = computedStyle.top;
        startHandleLeft = computedStyle.left;

        // stop momentum on image
        clearInterval(slideMomentumCheck);
        cancelAnimationFrame(sliderMomentumFrame);
      },

      onMove: (e, position) => {
        const { snapHandleDim, snapImageDim } = this._state;
        const { image } = this._elements;

        const imageCurrentDim = this.getImageCurrentDim();

        // find handle left and top and make sure they lay between the snap image
        const maxLeft = Math.max(snapImageDim.w - snapHandleDim.w, startHandleLeft);
        const maxTop = Math.max(snapImageDim.h - snapHandleDim.h, startHandleTop);
        const minLeft = Math.min(0, startHandleLeft);
        const minTop = Math.min(0, startHandleTop);

        const left = clamp(startHandleLeft + position.dx, minLeft, maxLeft);
        const top = clamp(startHandleTop + position.dy, minTop, maxTop);

        const imgLeft = -left * imageCurrentDim.w / snapImageDim.w;
        const imgTop = -top * imageCurrentDim.h / snapImageDim.h;

        snapHandle.style.left = `${left}px`;
        snapHandle.style.top = `${top}px`;

        image.style.left = `${imgLeft}px`;
        image.style.top = `${imgTop}px`;
      },
    });

    snapSlider.init();

    this._sliders.snapSlider = snapSlider;
  }

  // This is white handle in MiniView
  _initZoomSlider() {
    const { snapView, zoomHandle } = this._elements;

    // zoom in zoom out using zoom handle
    const sliderElm = snapView.querySelector('.iv-zoom-slider');

    let leftOffset;
    let handleWidth;

    // on zoom slider we have to follow the mouse and set the handle to its position.
    const zoomSlider = new Slider(sliderElm, {
      isSliderEnabled: () => this._state.loaded,
      onStart: () => {
        leftOffset = sliderElm.getBoundingClientRect().left;
        handleWidth = getElementDimensions(zoomHandle, toInt).width;
      },
      onMove: (e) => {
        const { maxZoom } = this._options;
        const { zoomSliderLength } = this._state;

        const newLeft = clamp(e.normalized.x - leftOffset - handleWidth / 2, 0, zoomSliderLength);

        const zoomValue = 100 + ((maxZoom - 100) * newLeft / zoomSliderLength);

        this.zoom(zoomValue);
      },
    });

    zoomSlider.init();
    this._sliders.zoomSlider = zoomSlider;
  }

  initEvents() {
    this._snapViewEvents();
    const { closeButton } = this._elements;

    // add close button event
    this.dom_events.onCloseBtnClick = assignEvent(closeButton, 'click', this.hide);
  }

  _snapViewEvents() {
    const { imageWrap, snapView } = this._elements;

    // show snapView on mouse move
    this.dom_events.snapViewOnMouseMove = assignEvent(imageWrap, ['touchmove', 'mousemove'], () => {
      this.showSnapView();
    });

    // keep showing snapView if on hover over it without any timeout
    this.dom_events.mouseEnterSnapView = assignEvent(snapView, ['mouseenter', 'touchstart'], () => {
      this._state.snapViewVisible = false;
      this.showSnapView(true);
    });

    // on mouse leave set timeout to hide snapView
    this.dom_events.mouseLeaveSnapView = assignEvent(snapView, ['mouseleave', 'touchend'], () => {
      this._state.snapViewVisible = false;
      this.showSnapView();
    });
  }

  _pinchAndZoom() {
    const { imageWrap, container } = this._elements;

    // apply pinch and zoom feature
    const onPinchStart = (eStart) => {
      const { loaded, zoomValue: startZoomValue } = this._state;
      const { dom_events: events } = this;

      if (!loaded) return;

      const [touch0, touch1] = eStart.touches;

      if (!(touch0 && touch1)) { return; }

      this._state.zooming = true;

      const contOffset = container.getBoundingClientRect();

      // find distance between two touch points
      const startDist = getTouchPointsDistance(eStart.touches);

      // find the center for the zoom
      const center = {
        x: (touch1.clientX + touch0.clientX) / 2 - contOffset.left,
        y: (touch1.clientY + touch0.clientY) / 2 - contOffset.top,
      };

      const moveListener = (eMove) => {
        // eMove.preventDefault();

        const newDist = getTouchPointsDistance(eMove.touches);

        const zoomValue = startZoomValue + ((newDist - startDist) / 2);

        this.zoom(zoomValue, center);
      };

      const endListener = (eEnd) => {
        // unbind events
        events.pinchMove();
        events.pinchEnd();
        this._state.zooming = false;
        // properly resume move event if one finger remains
        if (eEnd.touches.length === 1) {
          this._sliders.imageSlider.startHandler(eEnd);
        }
      };

      // remove events if already assigned
      if (events.pinchMove) events.pinchMove();
      if (events.pinchEnd) events.pinchEnd();

      // assign events
      events.pinchMove = assignEvent(document, 'touchmove', moveListener);
      events.pinchEnd = assignEvent(document, 'touchend', endListener);
    };

    this.dom_events.pinchStart = assignEvent(imageWrap, 'touchstart', onPinchStart);
  }

  _scrollZoom() {
    /* Add zoom interaction in mouse wheel */
    const { _options } = this;
    const { container, imageWrap } = this._elements;

    let changedDelta = 0;

    const onMouseWheel = (e) => {
      const { loaded, zoomValue } = this._state;

      if (!_options.zoomOnMouseWheel || !loaded) return;

      // clear all animation frame and interval
      this._clearFrames();

      const delta = normalizedMouseDelta(e);

      const newZoomValue = zoomValue * (100 + delta * ZOOM_CONSTANT) / 100;

      const isOutOfRange = !inRange(newZoomValue, { min: 100, max: _options.maxZoom });
      if (isOutOfRange) {
        changedDelta += Math.abs(delta);
      } else {
        changedDelta = 0;
      }

      e.preventDefault();

      if (changedDelta > MOUSE_WHEEL_COUNT) return;

      const contOffset = container.getBoundingClientRect();

      this.zoom(newZoomValue, {
        x: e.clientX - contOffset.left,
        y: e.clientY - contOffset.top,
      });

      // show the snap viewer
      this.showSnapView();
    };

    this._ev = assignEvent(imageWrap, 'wheel', onMouseWheel);
  }

  // TODO: fix edge cases
  doubleTapToZoom() {
    const { imageWrap } = this._elements;

    let touchTime = 0;
    let point;

    const CLOSE_TRESHOLD = 50;

    const isFast = e => e.timeStamp - touchTime < 500;
    const isClose = e => Math.abs(e.pageX - point.x) < CLOSE_TRESHOLD && Math.abs(e.pageY - point.y) < CLOSE_TRESHOLD;

    const onDoubleTap = (e) => {
      if (touchTime === 0) {
        touchTime = e.timeStamp;
        point = {
          x: e.pageX,
          y: e.pageY,
        };
      } else if (isFast(e) && isClose(e)) {
        this.isUnZoomed() ? this.zoom(200) : this.resetZoom();
        touchTime = 0;
      } else {
        touchTime = 0;
      }
    };

    assignEvent(imageWrap, 'click', onDoubleTap);
  }

  isUnZoomed() {
    return this.zoomLevel() === 1;
  }

  zoomLevel() {
    return toInt(Math.round(this._state.zoomValue / 100));
  }

  getImageCurrentDim() {
    const { zoomValue, imageDim } = this._state;
    return {
      w: imageDim.w * (zoomValue / 100),
      h: imageDim.h * (zoomValue / 100),
    };
  }

  _loadImages() {
    const { _images, _elements } = this;
    const { imageSrc } = _images;
    const { container, snapImageWrap, imageWrap } = _elements;

    // remove old images
    remove(container.querySelectorAll('.iv-snap-image, .iv-image'));

    // add snapView image
    const snapImage = createElement({
      tagName: 'img',
      className: 'iv-snap-image',
      src: imageSrc,
      insertBefore: snapImageWrap.firstChild,
      parent: snapImageWrap,
    });

    // add image
    const image = createElement({
      tagName: 'img',
      className: 'iv-image',
      src: imageSrc,
      parent: imageWrap,
    });

    this._state.loaded = false;

    // store image reference in _elements
    this._elements.image = image;
    this._elements.snapImage = snapImage;

    this.showLoader();

    // keep visibility hidden until image is loaded
    image.style.visibility = 'hidden';

    // hide snap view if open
    this.hideSnapView();

    if (imageLoaded(image)) {
      this.onImageLoadSuccess(image);
    } else {
      image.onload = this.onImageLoadSuccess.bind(this, image);
      image.onerror = this.onImageLoadError.bind(this, image);
    }
  }

  showLoader() {
    this._elements.loader.style.display = 'block';
  }

  hideLoader() {
    this._elements.loader.style.display = 'none';
  }

  onImageLoadSuccess(image) {
    this._state.loaded = true;

    this.emit('image:load-success', image);
    this.onImageLoadComplete(image);
  }

  onImageLoadComplete(image) {
    this.hideLoader();

    // show the image
    image.style.visibility = 'visible';

    // calculate the dimension
    this._calculateDimensions();

    // reset the zoom
    this.resetZoom();
  }

  onImageLoadError(image) {
    this.emit('image:load-error', image);
    this.onImageLoadComplete(image);
  }

  resetStyle(el) {
    el.removeAttribute('style');
  }

  _calculateDimensions() {
    const { image, container, snapView, snapImage, zoomHandle } = this._elements;

    this.resetStyle(image);

    const imageDim = getElementDimensions(image, toInt);
    const imageOriginalWidth = imageDim.width;
    const imageOriginalHeight = imageDim.height;

    let imgWidth = imageOriginalWidth;
    let imgHeight = imageOriginalHeight;

    const contDim = getElementDimensions(container, toInt);
    const contWidth = contDim.width;
    const contHeight = contDim.height;

    const snapViewWidth = snapView.clientWidth;
    const snapViewHeight = snapView.clientHeight;

    // set the container dimension
    this._state.containerDim = {
      w: contWidth,
      h: contHeight,
    };

    const ratio = imageOriginalWidth / imageOriginalHeight;

    if (imageOriginalWidth > contWidth || imgHeight > contHeight) {
      imgWidth = (imageOriginalWidth > imageOriginalHeight && contHeight >= contWidth) || ratio * contHeight > contWidth
        ? contWidth
        : ratio * contHeight;

      imgHeight = imgWidth / ratio;
    }

    this._state.imageDim = {
      w: imgWidth,
      h: imgHeight,
    };

    // reset image position and zoom
    image.style.width = `${imgWidth}px`;
    image.style.height = `${imgHeight}px`;
    image.style.left = `${(contWidth - imgWidth) / 2}px`;
    image.style.top = `${(contHeight - imgHeight) / 2}px`;
    image.style.maxWidth = 'none';
    image.style.maxHeight = 'none';

    // set the snap Image dimension
    const snapWidth = imgWidth > imgHeight ? snapViewWidth : imgWidth * snapViewHeight / imgHeight;
    const snapHeight = imgHeight > imgWidth ? snapViewHeight : imgHeight * snapViewWidth / imgWidth;

    this._state.snapImageDim = {
      w: snapWidth,
      h: snapHeight,
    };

    snapImage.style.width = `${snapWidth}px`;
    snapImage.style.height = `${snapHeight}px`;

    // calculate zoom slider area
    this._state.zoomSliderLength = snapViewWidth - zoomHandle.offsetWidth;
  }

  resetZoom(animate = true) {
    const { zoomValue } = this._options;

    if (!animate) {
      this._state.zoomValue = zoomValue;
    }

    this.zoom(zoomValue);
  }

  onZoomEnd() {
    this.emit('zoom:end', this);
  }

  onZoomStart() {
    this.emit('zoom:start', this);
  }

  get isZoomed() {
    return Math.abs(100 - this._state.zoomValue) > 4;
  }

  onZoomStartDebounced = debounce(this.onZoomStart, 200, { leading: true })
  onZoomEndDebounced = debounce(this.onZoomEnd, 300)

  zoom = (toPercentage, toPoint) => {
    const { _options, _elements, _state } = this;
    const { zoomValue: curPerc, imageDim, containerDim, zoomSliderLength } = _state;
    const { image, zoomHandle } = _elements;
    const { maxZoom, zoomValue } = _options;

    const perc = clamp(toPercentage, zoomValue, maxZoom);

    const point = toPoint || squareCenter(containerDim);

    const imageComputedDim = getElementDimensions(image, toFloat);
    const curLeft = imageComputedDim.left;
    const curTop = imageComputedDim.top;

    // clear any panning frames
    this._clearFrames();

    let step = 0;

    const baseLeft = (containerDim.w - imageDim.w) / 2;
    const baseTop = (containerDim.h - imageDim.h) / 2;
    const baseRight = containerDim.w - baseLeft;
    const baseBottom = containerDim.h - baseTop;

    this.onZoomStartDebounced();
    const zoom = () => {
      step++;

      if (step < 16) {
        this.frames.zoomFrame = requestAnimationFrame(zoom);
      }

      const tickZoom = easeOutQuart(step, curPerc, perc - curPerc, 16);

      const ratio = tickZoom / curPerc;

      const imgWidth = imageDim.w * tickZoom / 100;
      const imgHeight = imageDim.h * tickZoom / 100;

      let newLeft = -((point.x - curLeft) * ratio - point.x);
      let newTop = -((point.y - curTop) * ratio - point.y);

      // fix for left and top
      newLeft = Math.min(newLeft, baseLeft);
      newTop = Math.min(newTop, baseTop);

      // fix for right and bottom
      if (newLeft + imgWidth < baseRight) {
        newLeft = baseRight - imgWidth; // newLeft - (newLeft + imgWidth - baseRight)
      }

      if (newTop + imgHeight < baseBottom) {
        newTop = baseBottom - imgHeight; // newTop + (newTop + imgHeight - baseBottom)
      }

      image.style.height = `${imgHeight}px`;
      image.style.width = `${imgWidth}px`;
      image.style.left = `${newLeft}px`;
      image.style.top = `${newTop}px`;


      this._state.zoomValue = tickZoom;

      this._resizeSnapHandle(imgWidth, imgHeight, newLeft, newTop);

      // update zoom handle position
      zoomHandle.style.left = `${(tickZoom - 100) * zoomSliderLength / (maxZoom - 100)}px`;
    };

    zoom();
    this.onZoomEndDebounced();
  }

  _clearFrames = () => {
    const { slideMomentumCheck, sliderMomentumFrame, zoomFrame } = this.frames;
    clearInterval(slideMomentumCheck);
    cancelAnimationFrame(sliderMomentumFrame);
    cancelAnimationFrame(zoomFrame);
  }

  _resizeSnapHandle = (imgWidth, imgHeight, imgLeft, imgTop) => {
    const { _elements, _state } = this;
    const { snapHandle, image } = _elements;
    const { imageDim, containerDim, zoomValue, snapImageDim } = _state;

    const imageWidth = imgWidth || imageDim.w * zoomValue / 100;
    const imageHeight = imgHeight || imageDim.h * zoomValue / 100;

    const imageCurrentDim = getElementDimensions(image, toFloat);
    const imageLeft = imgLeft || imageCurrentDim.left;
    const imageTop = imgTop || imageCurrentDim.top;

    const left = -imageLeft * snapImageDim.w / imageWidth;
    const top = -imageTop * snapImageDim.h / imageHeight;

    const handleWidth = (containerDim.w * snapImageDim.w) / imageWidth;
    const handleHeight = (containerDim.h * snapImageDim.h) / imageHeight;

    snapHandle.style.top = `${top}px`;
    snapHandle.style.left = `${left}px`;
    snapHandle.style.width = `${handleWidth}px`;
    snapHandle.style.height = `${handleHeight}px`;


    this._state.snapHandleDim = {
      w: handleWidth,
      h: handleHeight,
    };
  }

  showSnapView = (noTimeout) => {
    const { snapViewVisible, zoomValue, loaded } = this._state;
    const { snapView } = this._elements;

    if (!this._options.snapView) return;

    if (snapViewVisible || zoomValue <= 100 || !loaded) return;

    clearTimeout(this.frames.snapViewTimeout);

    this._state.snapViewVisible = true;

    snapView.style.opacity = 1;
    snapView.style.pointerEvents = 'inherit';

    if (!noTimeout) {
      this.frames.snapViewTimeout = setTimeout(this.hideSnapView, 1500);
    }
  }

  hideSnapView = () => {
    const { snapView } = this._elements;
    snapView.style.opacity = 0;
    snapView.style.pointerEvents = 'none';

    this._state.snapViewVisible = false;
  }

  refresh = () => {
    this._calculateDimensions();
    this.resetZoom();
  }

  load(imageSrc) {
    this._images = { imageSrc };
    this._loadImages();
  }

  disableWindowScroll() {
    document.documentElement.style.overflow = 'hidden';
  }

  enableWindowScroll() {
    document.documentElement.style.removeProperty('overflow');
  }

  get downloadButton() {
    return this._elements.actions.querySelector('.iv-fullscreen-download');
  }

  createDownloadButton(src) {
    const el = document.createElement('a');

    el.className = 'iv-fullscreen-download';
    el.href = src.downloadUrl;
    el.download = src.title || true;

    return el;
  }

  updateDownloadButton(src) {
    this.downloadButton.href = src.downloadUrl;
    this.downloadButton.download = src.title || true;
  }

  insertDownloadButton(src) {
    this._elements.actions.prepend(this.createDownloadButton(src));
  }

  removeDownloadButton() {
    this.downloadButton?.remove();
  }

  renderDownloadButton(src) {
    this.downloadButton ? this.updateDownloadButton(src) : this.insertDownloadButton(src)
  }

  // Full screen
  show(src) {
    // show the element
    this._elements.fullScreen.style.display = 'block';

    // if image source is provide load image source
    src.url && this.load(src.url);
    // if image download source is provide load download action
    src.downloadUrl ? this.renderDownloadButton(src) : this.removeDownloadButton();

    // handle window resize
    this.dom_events.onWindowResize = assignEvent(window, 'resize', this.refresh);

    this.disableWindowScroll();
  }

  hide = (e) => {
    e.preventDefault();

    // hide the fullscreen
    this._elements.fullScreen.style.display = 'none';

    this.enableWindowScroll();

    // remove window event
    this.dom_events.onWindowResize();

    // Reset zoom so next open is from start
    this.resetZoom();
    this.destroy();
    this.onHide();
    this.emit('hide');
  }

  destroy() {
    const { container, fullScreen } = this._elements;
    // destroy all the sliders
    Object.values(this._sliders).forEach(slider => slider.destroy());

    // unbind all events
    Object.values(this.dom_events).forEach(unbindEvent => unbindEvent());

    // clear all the frames
    this._clearFrames();

    // remove html from the container
    remove(container.querySelector('.iv-wrap'));

    // remove added style from container
    document.documentElement.style.removeProperty('relative');

    this.enableWindowScroll();

    remove(fullScreen);

    this.constructor.unregister(this);
  }
}

Viewer.defaults = {
  zoomValue: 100,
  snapView: true,
  maxZoom: 800,
  refreshOnResize: false,
  zoomOnMouseWheel: true,
};

export default Viewer;
