import { noop } from './util';
import NormalizedEvent from './normalized_event';

const startEvents = ['touchstart', 'mousedown'];

class Slider {
  constructor(container, {
    onStart, onMove, onEnd, isSliderEnabled,
  }) {
    this.container = container;
    this.isSliderEnabled = isSliderEnabled;
    this.onStart = onStart || noop;
    this.onMove = onMove || noop;
    this.onEnd = onEnd || noop;
  }

  startHandler = (eStart) => {
    if (!this.isSliderEnabled()) return;

    this.removeListeners();

    eStart.preventDefault();

    const { moveHandler, endHandler, onStart } = this;

    eStart.normalized = new NormalizedEvent(eStart);
    const { x, y, moveEventName, endEventName } = eStart.normalized;

    this.touchMoveEvent = moveEventName;
    this.touchEndEvent = endEventName;

    this.sx = x;
    this.sy = y;

    onStart(eStart, { x, y });

    // add listeners
    document.addEventListener(this.touchMoveEvent, moveHandler);
    document.addEventListener(this.touchEndEvent, endHandler);
    /*
      add end handler in context menu as well.
      As mouseup event is not trigger on context menu open
      https://bugs.chromium.org/p/chromium/issues/detail?id=506801
    */
    document.addEventListener('contextmenu', endHandler);
  }

  moveHandler = (eMove) => {
    if (!this.isSliderEnabled()) return;

    eMove.preventDefault();
    const { sx, sy, onMove } = this;

    eMove.normalized = new NormalizedEvent(eMove);
    const { x, y } = eMove.normalized;

    onMove(eMove, {
      dx: x - sx,
      dy: y - sy,
      mx: x,
      my: y,
    });
  }

  endHandler = () => {
    if (!this.isSliderEnabled()) return;
    this.removeListeners();
    this.onEnd();
  }

  // remove previous events if its not removed
  // - Case when while sliding mouse moved out of document and released there
  removeListeners() {
    if (!this.touchMoveEvent) return;
    document.removeEventListener(this.touchMoveEvent, this.moveHandler);
    document.removeEventListener(this.touchEndEvent, this.endHandler);
    document.removeEventListener('contextmenu', this.endHandler);
  }

  init() {
    startEvents.forEach(evt => this.container.addEventListener(evt, this.startHandler));
  }

  destroy() {
    startEvents.forEach(evt => this.container.removeEventListener(evt, this.startHandler));
    this.removeListeners();
  }
}

export default Slider;
