import * as CONSTANTS from './constants';
import TurbolinksNativeAdapter from './turbolinks_native_adapter';
import TurboNativeBridge from './turbo_native_bridge';

const TurboNativeAdapter = {
  ...CONSTANTS,
  ...TurboNativeBridge,
  shouldUpdatePushToken: null,
  pushTokenUpdated: null,
  getAuthToken: null,

  loggedIn() {
    return this.shouldUpdatePushToken();
  },

  resetState() {
    console.log('[TurboNativeAdapters] resetState triggered... '); // eslint-disable-line
    this.pushToken = null;
    this.pushTokenUpdated = false;
  },
  /**
   * Call registerForPushNotifications once per session if
   * user is logged in
   */
  registerForPushNotificationsIfNeeded() {
    if (!this.pushTokenUpdated && this.shouldUpdatePushToken()) {
      TurboNativeBridge.registerForPushNotifications();
      this.pushTokenUpdated = true;
    }
  },

  onLoad() {
    // Reset state when user not logged in
    !this.loggedIn() && this.resetState();

    TurboNativeBridge.setAuthToken(this.getAuthToken());
    this.registerForPushNotificationsIfNeeded();
  },

  register() {
    window.TurboNativeBridge = this;
  },

  start() {
    // eslint-disable-next-line no-console
    console.log('[TurboNativeAdapters] started');
    this.register();
    document.addEventListener('turbo:load', () => this.onLoad());
    document.addEventListener('turbolinks:load', () => this.onLoad());
  },
};

const Adapter = CONSTANTS.IS_TURBO ? TurboNativeAdapter : TurbolinksNativeAdapter;

export default Adapter;
