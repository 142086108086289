/* IE 11 Needs CustomEvent polyfill */

let hidden = 'hidden';

function onchange(evt = window.event) {
  let state;
  const v = 'visible';
  const h = 'hidden';
  const evtMap = {
    focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h,
  };

  if (evt.type in evtMap) {
    state = evtMap[evt.type];
  } else {
    state = this[hidden] ? 'hidden' : 'visible';
  }
  const pageIsActive = state === 'visible';

  window.pageIsActive = pageIsActive;
  const eventHumanName = pageIsActive ? 'on' : 'off';

  document.dispatchEvent(new CustomEvent('visibility', { detail: { pageIsActive } }));
  document.dispatchEvent(new CustomEvent(`visibility:${eventHumanName}`));
}

function withHidden(newHidden) {
  hidden = newHidden;
  return hidden;
}

// Standards:
if (hidden in document) {
  document.addEventListener('visibilitychange', onchange);
} else if (withHidden('mozHidden') in document) {
  document.addEventListener('mozvisibilitychange', onchange);
} else if (withHidden('webkitHidden') in document) {
  document.addEventListener('webkitvisibilitychange', onchange);
} else if (withHidden('msHidden') in document) {
  document.addEventListener('msvisibilitychange', onchange);
} else if ('onfocusin' in document) {
  // IE 9 and lower:
  document.onfocusin = document.onfocusout = onchange; // eslint-disable-line
} else {
  // All others:
  window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange; // eslint-disable-line
}

// set the initial state (but only if browser supports the Page Visibility API)
if (document[hidden] !== undefined) onchange({ type: document[hidden] ? 'blur' : 'focus' }); // eslint-disable-line
