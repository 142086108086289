import debounce from 'lodash/debounce';
import './auto_hide_header.scss';

const MATRIX_3D_Y_INDEX = 13;
const MATRIX_2D_Y_INDEX = 5;

export const UP = 1;
export const DOWN = -1;

export default class AutoHideHeader {
  static defaults = {
    subheader: null, // Additional element included in show/hide transition.
    snappingDelta: null, // Minimum distance in px required for menu snaps to open or close position when scroll ends.
    directionDelta: 0, // Minimum distance in px required for the direction to be reversed while scrolling.
    transitionOffset: 0, // Offset show/hide transition value in px.
    disableAboveViewportWidth: null, // Disable AutoHideHeader above given breakpoint.
    cssSelectors: {
      header: 'auto-hide-header',
      subheader: 'auto-hide-subheader',
      up: 'auto-hide-up',
      down: 'auto-hide-down',
      scrolling: 'auto-hide-scrolling',
    },
  }

  static all = [];

  static destroyAll() {
    this.all.forEach(i => i.destroy());
  }

  constructor(header, options = {}) {
    this.options = { ...this.constructor.defaults, ...options };
    this.header = this.initializeElement(header);

    if (!this.header) { return; }

    this.subheader = this.initializeElement(this.options.subheader);
    this.updateHeaderHeight();
    this.snappingDelta = this.headerHeight / 2;
    this.setDomSelectors();
    this.setupEvents();
    this.constructor.all.push(this);
  }

  get scrollingClass() {
    return this.options.cssSelectors.scrolling;
  }

  initializeElement(domOrString) {
    return typeof domOrString === 'string' ? document.querySelector(domOrString) : domOrString;
  }

  parseTranslateY(transform) {
    if (!transform || !transform.includes('matrix')) { return 0; }

    const [, type = '2d', matrix] = transform.match(/matrix(3d)?\((.+)\)/);

    const is3d = type === '3d';
    const values = matrix.split(/\s*,\s*/);

    const yIndexPos = is3d ? MATRIX_3D_Y_INDEX : MATRIX_2D_Y_INDEX;

    return values[yIndexPos] || 0;
  }

  getTranslateY(el) {
    const style = getComputedStyle(el);
    return this.parseTranslateY(style.transform || style.webkitTransform || style.mozTransform);
  }

  getScrollPosition() {
    let sp = document.body.getBoundingClientRect().top;
    const { scrollHeight, offsetHeight } = document.documentElement;

    if (Math.abs(sp) > scrollHeight - offsetHeight) {
      sp = -(scrollHeight - offsetHeight);
    }

    return sp < 0 ? sp : 0;
  }

  getScrollDirection() {
    const currentScrollPosition = this.getScrollPosition();

    const scrollDirection = currentScrollPosition >= this.directionPosition ? UP : DOWN;

    this.directionPosition = currentScrollPosition;
    return scrollDirection;
  }

  getValue() {
    let value = this.translatedValue - this.dragOffset + this.scrollPosition;

    if (value > 0) {
      value = 0;
    } else if (value < -this.headerHeight) {
      value = -this.headerHeight;
    }

    return value;
  }

  getDirectionChangeOffset() {
    const currentScrollPosition = this.getScrollPosition();
    let offset;

    this.scrollDirection === this.oldDirection && this.setScrollDirectionOffsetPosition();

    if (this.scrollDirection === UP) {
      offset = this.scrollDirectionOffsetPosition - currentScrollPosition;
    } else {
      offset = currentScrollPosition - this.scrollDirectionOffsetPosition;
    }

    return Math.abs(offset);
  }

  setScrollPosition() {
    this.scrollPosition = this.getScrollPosition();
  }

  setScrollDirection() {
    this.scrollDirection = this.getScrollDirection();
  }

  setScrollDirectionOffsetPosition() {
    this.scrollDirectionOffsetPosition = this.getScrollPosition();
  }

  setStyle() {
    const value = this.getValue();
    this.header.style.transform = `translateY(${value}px)`;
    if (this.subheader) { this.subheader.style.transform = `translateY(${value}px)`; }
  }

  setDomSelectors() {
    this.header.classList.add(this.options.cssSelectors.header);
    this.subheader && this.subheader.classList.add(this.options.cssSelectors.subheader);
  }

  isDirectionChanged() {
    if (this.getDirectionChangeOffset() < this.options.directionDelta) { return false; }
    if (this.scrollDirection === this.oldDirection) { return false; }
    this.oldDirection = this.scrollDirection;
    return true;
  }

  headerUp() {
    this.header.classList.remove(this.options.cssSelectors.down);
    this.header.classList.add(this.options.cssSelectors.up);
    this.header.style.transform = `translateY(-${this.headerHeight}px)`;
    if (this.subheader) { this.subheader.style.transform = `translateY(-${this.headerHeight}px)`; }
  }

  headerDown() {
    this.header.classList.remove(this.options.cssSelectors.up);
    this.header.classList.add(this.options.cssSelectors.down);
    this.header.style.transform = 'translateY(0)';
    if (this.subheader) { this.subheader.style.transform = 'translateY(0)'; }
  }

  scrollEnd() {
    this.scrollStarted = false;
    this.removeScrollingCssClasses();

    if (this.getScrollPosition() >= -this.snappingDelta) {
      this.headerDown();
      return;
    }

    const translateYHeader = this.getTranslateY(this.header);

    if (this.scrollDirection === DOWN) {
      translateYHeader < -this.snappingDelta ? this.headerUp() : this.headerDown();
    } else {
      translateYHeader < -this.headerHeight + this.snappingDelta ? this.headerUp() : this.headerDown();
    }
  }

  scrollStartHandler() {
    this.addScrollingCssClasses();

    this.scrollStarted = true;
    this.translatedValue = this.getTranslateY(this.header);
    this.dragOffset = this.scrollPosition;
    this.directionPosition = this.getScrollPosition();
  }

  addScrollingCssClasses() {
    const { header, subheader, scrollingClass } = this;

    header.classList.add(scrollingClass);
    subheader && subheader.classList.add(scrollingClass);
  }

  removeScrollingCssClasses() {
    const { header, subheader, scrollingClass } = this;

    header.classList.remove(scrollingClass);
    subheader && subheader.classList.remove(scrollingClass);
  }

  scrollEndHandler() {
    if (this.isTouchEvent) { return; }

    this.scrollEnd();
  }

  scrollDirectionChangeHandler() {
    this.translatedValue = this.getTranslateY(this.header);
    this.dragOffset = this.scrollPosition;
  }

  updateHeaderHeight() {
    this.headerHeight = this.header.offsetHeight + this.options.transitionOffset;
  }

  // Handle events *****************************************************

  handleEvent(event) {
    this[`on${event.type}`](event);
  }

  onscroll() {
    if (this.options.disableAboveViewportWidth && window.innerWidth > this.options.disableAboveViewportWidth) { return; }

    clearTimeout(this.timeout);
    this.setScrollPosition();
    !this.scrollStarted && this.scrollStartHandler();

    this.setScrollDirection();
    this.isDirectionChanged() && this.scrollDirectionChangeHandler();
    this.setStyle();
    this.timeout = setTimeout(() => this.scrollEndHandler(), 200);
  }

  ontouchstart() {
    this.isTouchEvent = true;
  }

  ontouchend() {
    this.scrollEnd();
    this.isTouchEvent = false;
  }

  onresize = debounce(this.updateHeaderHeight, 200)

  destroy() {
    this.headerDown();
    document.removeEventListener('scroll', this, { passive: true });
    document.removeEventListener('touchstart', this);
    document.removeEventListener('touchend', this);
    window.removeEventListener('resize', this);
  }

  setupEvents() {
    document.addEventListener('scroll', this, { passive: true });
    document.addEventListener('touchstart', this);
    document.addEventListener('touchend', this);
    window.addEventListener('resize', this);
  }
}
