import './style.scss';

export class Snackbar {
  static all = [];
  static defaults = {
    text: 'Default Text',
    textColor: '#FFFFFF',
    width: 'auto',
    showAction: false,
    actionText: 'Dismiss',
    actionTextColor: '#4CAF50',
    showSecondButton: false,
    secondButtonText: '',
    secondButtonTextColor: '#4CAF50',
    backgroundColor: '#323232',
    pos: 'bottom-left',
    duration: 5000,
    customClass: '',
  };

  static configure(settings = {}) {
    Object.assign(Snackbar.defaults, settings);
  }

  static closeAll() {
    this.all.forEach(snackbar => snackbar.close());
  }

  static removeAll() {
    this.all.forEach(snackbar => snackbar.remove());
  }

  static show(options) {
    this.removeAll();
    new this(options).setup().show();
  }

  constructor(options = {}) {
    // this.options = Object.assign({}, this.constructor.defaults, options);
    this.options = { ...this.constructor.defaults, ...options };
    this.el = document.createElement('div');

    this.constructor.all.push(this);
  }

  onActionClick() { this.close(); }
  onSecondaryClick() { }

  createButton({ text, color, type }) {
    const button = document.createElement('button');
    button.className = `action action-${type}`;
    button.innerHTML = text;
    button.style.color = color;
    this.el.appendChild(button);
  }

  createContent() {
    const { options, el } = this;

    const content = document.createElement('p');
    Object.assign(content.style, {
      margin: 0,
      padding: 0,
      color: options.textColor,
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: '1em',
    });

    content.innerHTML = options.text;
    el.appendChild(content);
  }

  createElement() {
    const { el, options } = this;
    el.className = `snackbar-container ${options.customClass} snackbar-pos ${options.pos}`;
    el.style.width = options.width;
    el.style.background = options.backgroundColor;

    this.createContent();

    if (options.showSecondButton) {
      this.createButton({ type: 'secondary', text: options.secondButtonText, color: options.secondButtonTextColor });
    }

    if (options.showAction) {
      this.createButton({ type: 'primary', text: options.actionText, color: options.actionTextColor });
    }
  }

  hideOnTimeout() {
    this.timer = setTimeout(() => this.close(), this.options.duration);
  }

  insert() {
    document.body.appendChild(this.el);
    return this;
  }

  removeFromAll() {
    const { all } = this.constructor;
    const index = all.indexOf(this);
    all.splice(index);
  }

  remove() {
    const parent = this.el.parentElement;
    parent && parent.removeChild(this.el);
    this.removeFromAll();
    clearTimeout(this.timer);
    this.el = null;
    return this;
  }

  setupEvents() {
    this.el.addEventListener('transitionend', () => this.remove());
    this.el.addEventListener('click', (e) => {
      e.preventDefault();
      e.target.closest('.action-primary') && this.onActionClick();
      e.target.closest('.action-secondary') && this.onSecondaryClick();
    });
  }

  show() {
    this.el.style.opacity = 1;
    return this;
  }

  setPosition() {
    const { options: { pos }, el } = this;

    if (['top-left', 'top-center', 'top', 'top-right'].includes(pos)) {
      el.style.top = '-100px';
    }

    if (pos === 'top-left' || pos === 'top-right') {
      el.style.top = 0;
    } else if (pos === 'top-center' || pos === 'top') {
      el.style.top = '25px';
    } else if (pos === 'bottom-center' || pos === 'bottom') {
      el.style.bottom = '-25px';
    }
  }

  setup() {
    this.createElement();
    this.insert();
    this.setupEvents();
    this.hideOnTimeout();
    this.setPosition();
    return this;
  }

  close() {
    this.el.style.opacity = 0;
    return this;
  }
}

export default function Snack(opts) {
  return Snackbar.show(opts);
}
