import Viewer from './viewer';
import './gallery.scss';

export default class Gallery {
  static destroyAll() {
    Viewer.destroyAll();
  }

  constructor(data, options) {
    this.currentImageId = parseInt(data.id, 10) || 1;
    this.json = data.json;
    this.galleryDataUrl = !this.json && data.galleryUrl;
    this.currentImageIndex = 0;
    this.options = options;
    this.setupUI();
  }

  getJSON() {
    // [
    //   {
    //     "id": 1,
    //     "title": "Image title",
    //     "url": "/image.jpg",
    //     "downloadUrl": "/image_original.jpg",
    //   }
    // ]
    return fetch(this.galleryDataUrl)
      .then(res => res.json())
      .catch(() => null)
      .then(json => this.json = json);
  }

  load() {
    if (this.json) {
      this.onLoad();
      return true;
    }

    return this.getJSON().then((json) => {
      json && this.onLoad();
    });
  }

  get currentImage() {
    return this.json[this.currentImageIndex];
  }

  get nextImage() {
    return this.json[this.currentImageIndex + 1];
  }

  get prevImage() {
    return this.json[this.currentImageIndex - 1];
  }

  get actions() {
    return this.viewer._elements.actions;
  }

  get length() {
    return this.json.length;
  }

  setOptions() {
    if (!this.options) { return; }

    this.options.onHide && this.viewer.on('hide', () => this.options.onHide());
  }

  hideUI() {
    this.ui.container.style.opacity = '0';
  }

  showUI() {
    this.ui.container.style.opacity = '1';
  }

  setupUI() {
    const container = document.createElement('div');
    container.classList.add('iv-fullscreen-controls');

    container.innerHTML = `
      <a href="#" data-direction="prev" class="iv-gallery-prev">
        <span></span>
      </a>
      <a href="#" data-direction="next" class="iv-gallery-next">
        <span></span>
      </a>
    `;

    this.ui = {
      container,
      prev: container.querySelector('[data-direction="prev]'),
      next: container.querySelector('[data-direction="next]'),
    };
  }

  injectUI() {
    this.viewer._elements.fullScreen.appendChild(this.ui.container);
  }

  setupEvents() {
    this.ui.container.addEventListener('click', (e) => {
      const currentTarget = e.target.closest('[data-direction]');
      if (!currentTarget) { return; }

      e.preventDefault();
      this[currentTarget.dataset.direction]();
    });

    this.viewer
      .on('zoom:start', ({ isZoomed }) => {
        isZoomed && this.hideUI();
      })
      .on('zoom:end', ({ isZoomed }) => {
        isZoomed ? this.hideUI() : this.showUI();
      });
  }

  next() {
    if (!this.nextImage) {
      this.currentImageIndex = -1;
    }

    this.currentImageIndex++;
    this.show();
  }

  prev() {
    if (!this.prevImage) {
      this.currentImageIndex = this.length;
    }

    this.currentImageIndex--;
    this.show();
  }

  show() {
    this.viewer.show(this.json[this.currentImageIndex]);
  }

  findCurrentImageIndex() {
    this.currentImageIndex = this.isSingleImage() ? 0 : this.json.findIndex(img => img.id === this.currentImageId);
  }

  isSingleImage() {
    return this.length <= 1;
  }

  onLoad() {
    this.viewer = new Viewer();
    this.setOptions();
    this.findCurrentImageIndex();
    this.viewer.show(this.json[this.currentImageIndex]);

    if (this.isSingleImage()) { return; }

    this.injectUI();
    this.setupEvents();
  }
}
